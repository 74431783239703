import LayoutLogin from "../components/layout_login"
import React from "react"
import Message from "../components/common/message"
import { Unsubscribe as unsub } from "../services/api"
import Loading from "../components/common/loading";


class Unsubscribe extends React.Component {
	constructor() {
		super();
		
		this.state = {
			error: "",
			message: ""
		}

		this.doUnsubscribe()
	}

	findGetParameter(parameterName) {
		var result = null,
			 tmp = [];
			 if(typeof window != 'undefined') {
		window.location.search
			 .substring(1)
			 .split("&")
			 .forEach(function (item) {
				tmp = item.split("=");
				if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
			 });
			}
		return result;
  }

	doUnsubscribe() {
		if(this.state.error === "" && this.state.message === "") {
			const id = parseInt(this.findGetParameter('id'));
			const hash = this.findGetParameter('hash');

			if ( (id > 0) === false || hash === null || hash === "") {
				this.setState({error: "Invalid link, missing parameters"})
				return
			}
		
			unsub(id, hash).then(result => {
				if(result.Success) {
					this.setState({"message": "You have been unsubscribed successfully"})
				} else {
					this.setState({"error": result.Error})
				}
			})
		}
	}

	render() {
		let loading = (<></>)

		if(this.state.error == "" && this.state.message == "") {
			loading = (<p>Loading..</p>)
		}
		return (
			<LayoutLogin>
				{loading}
				<Message type="error" message={this.state.error} />
				
				<Message type="success" message={this.state.message} />
			</LayoutLogin>
		)
	}
	
}

export default Unsubscribe;